<template>
  <div class="register pb-5">
    <div class="container">
      <div class="row mb-5">
        <div class="col-sm-10 mx-auto text-center">
          <a href="https://bit.ly/4bw6MGc" target="_blank">
            <img src="@/assets/img/banner.png" class="img-fluid" alt="banner"
          /></a>
        </div>

        <div class="px-2">
          <div
            class="bg-light border rounded d-flex align-items-center justify-content-center py-3 mt-5"
          >
            <p class="m-0 fw-bold h3">Registration has been closed!</p>
          </div>
        </div>
        <!-- <div class="col-sm-10 mx-auto py-4">
          <div class="mb-4">
            <p class="fs-3 fw-bold mb-1">Registration</p>
            <p class="mb-0" style="font-size: 14px">
              Please fill out the following registration form.
            </p>
            <p style="font-size: 14px">
              Please register using a corporate/professional email address that
              matches your organisation name to increase the chances of your
              registration being accepted. All registrations are subject to
              approval.
            </p>
          </div>

          <div class="row">
            <div class="row col-12 pe-0 pe-sm-3">
              <div class="col-sm-6 col-12 mb-3 pe-0">
                <label class="form-label">Salutation<span class="text-danger">*</span>:</label>
                <select class="form-select text-primary" id="salutation" v-model="data.salutation">
                  <option selected value="">Please select</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Amb.">Amb.</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.salutation"
                  :key="`salutation-${message}`">{{ message }}<br /></span>
              </div>
            </div>

            <div class="col-sm-6 mb-3">
              <label class="form-label">First Name<span class="text-danger">*</span>:</label>
              <input type="text" class="form-control" id="first_name" v-model="data.first_name" />
              <span class="text-danger" v-for="message of validation_message.first_name"
                :key="`first_name-${message}`">{{ message }}<br /></span>
            </div>
            <div class="col-sm-6 mb-3">
              <label class="form-label">Last Name<span class="text-danger">*</span>:</label>
              <input type="text" class="form-control" id="last_name" v-model="data.last_name" />
              <span class="text-danger" v-for="message of validation_message.last_name" :key="`last_name-${message}`">{{
                message }}<br /></span>
            </div>

            <div class="row pe-0 pe-sm-3">
              <div class="col-sm-6 mb-3 pe-0">
                <label class="form-label">Printed Name<span class="text-danger">*</span>:</label>
                <input type="text" class="form-control" id="printed_name" v-model="data.printed_name" />
                <span class="text-danger" v-for="message of validation_message.printed_name"
                  :key="`printed_name-${message}`">{{ message }}<br /></span>
              </div>
            </div>

            <div class="col-sm-6 mb-3">
              <label class="form-label">Email Address<span class="text-danger">*</span>:</label>
              <input type="text" class="form-control" id="email" v-model="data.email" />
              <span class="text-danger" v-for="message of validation_message.email" :key="`email-${message}`">{{ message
                }}<br /></span>
            </div>

            <div class="col-sm-6 mb-3">
              <label class="form-label">Office/Mobile Number:</label>
              <input type="text" class="form-control" id="phone" v-model="data.phone" />
              <span class="text-danger" v-for="message of validation_message.phone" :key="`phone-${message}`">{{ message
                }}<br /></span>
            </div>

            <div class="col-sm-6 mb-3">
              <label class="form-label">Designation<span class="text-danger">*</span>:</label>
              <input type="text" class="form-control" v-model="data.designation" id="designation" />
              <span class="text-danger" v-for="message of validation_message.designation"
                :key="`designation-${message}`">{{ message }}<br /></span>
            </div>

            <div class="col-sm-6 mb-3">
              <label class="form-label">Organisation<span class="text-danger">*</span>:</label>
              <input type="text" class="form-control" v-model="data.organization" id="organization" />
              <span class="text-danger" v-for="message of validation_message.organization"
                :key="`organization-${message}`">{{ message }}<br /></span>
            </div>

            <div class="row pe-0 pe-sm-3">
              <div class="col-sm-6 mb-3 pe-0">
                <label class="form-label">Job Level<span class="text-danger">*</span>:</label>
                <select class="form-select text-primary" v-model="data.job_level" id="job_level">
                  <option selected value="">Please select</option>
                  <option value="C-Level (CEO, CFO, Chairman, Managing Partners)">
                    C-Level (CEO, CFO, Chairman, Managing Partners)
                  </option>
                  <option value="Management (Directors, VPs, Senior Partners, Department Heads)">
                    Management (Directors, VPs, Senior Partners, Department
                    Heads)
                  </option>
                  <option value="Managers, Consultants, Associates">
                    Managers, Consultants, Associates
                  </option>
                  <option value="Executives, Others">Executives, Others</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.job_level"
                  :key="`job_level-${message}`">{{ message }}<br /></span>
              </div>
            </div>

            <div class="row pe-0 pe-sm-3">
              <div class="col-sm-6 mb-3 pe-0">
                <label class="form-label">Industry<span class="text-danger">*</span>:</label>
                <select class="form-select text-primary" v-model="data.industry" id="industry">
                  <option selected value="">Please select</option>
                  <option value="Academia, Education">
                    Academia, Education
                  </option>
                  <option value="Agri-business, Forestry">
                    Agri-business, Forestry
                  </option>
                  <option value="Consultancy">Consultancy</option>
                  <option value="Financial Services">Financial Services</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Oil & Gas">Oil & Gas</option>
                  <option value="Information Technology">
                    Information Technology
                  </option>
                  <option value="Logistics, Supply Chain">
                    Logistics, Supply Chain
                  </option>
                  <option value="Travel, Tourism, Hospitality">
                    Travel, Tourism, Hospitality
                  </option>
                  <option value="Government">Government</option>
                  <option value="NGOs">NGOs</option>
                  <option value="Media">Media</option>
                  <option value="Student">Student</option>
                  <option value="Others">Others</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.industry" :key="`industry-${message}`">{{
                  message }}<br /></span>
              </div>

              <div class="col-sm-6 mb-3" v-if="data.industry == 'Others'">
                <label class="form-label">Others <span class="text-danger">*</span>:</label>
                <input type="text" class="form-control mb-2" v-model="data.industry_other" id="industry_other" />
                <span class="text-danger" v-for="message of validation_message.industry_other"
                  :key="`industry_other-${message}`">{{ message }}<br /></span>
              </div>
            </div>

            <div class="col-sm-6 mb-3">
              <label class="form-label">Age<span class="text-danger">*</span>:</label>
              <select class="form-select text-primary" id="age" v-model="data.age">
                <option selected value="">Please select</option>
                <option value="16 - 29">16 - 29</option>
                <option value="30 - 45">30 - 45</option>
                <option value="46 - 60">46 - 60</option>
                <option value="61 and above">61 and above</option>
              </select>
              <span class="text-danger" v-for="message of validation_message.age" :key="`age-${message}`">{{ message
                }}<br /></span>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-sm-6 mb-3">
                  <label class="form-label">Nationality<span class="text-danger">*</span>:</label>
                  <input type="text" class="form-control" v-model="data.nationality" id="nationality" />
                  <span class="text-danger" v-for="message of validation_message.nationality"
                    :key="`nationality-${message}`">{{ message }}<br /></span>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-sm-12 mb-3">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input me-2" type="checkbox" id="person" value="acknowledgement"
                      v-model="data.person" />
                    <label class="form-check-label" for="person">I’m registering on behalf of this person</label>
                    <p class="fst-italic" style="font-size: 14px">
                      (Please enter your personal information)
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 mb-3" v-if="data.person">
                  <div class="row">
                    <div class="col-sm-6 mb-3">
                      <label class="form-label">Administrator’s First Name<span class="text-danger">*</span>:</label>
                      <input type="text" class="form-control" v-model="data.person_first_name" id="person_first_name" />
                      <span class="text-danger" v-for="message of validation_message.person_first_name"
                        :key="`person_first_name-${message}`">{{ message }}<br /></span>
                    </div>

                    <div class="col-sm-6 mb-3">
                      <label class="form-label">Administrator’s Last Name<span class="text-danger">*</span>:</label>
                      <input type="text" class="form-control" v-model="data.person_last_name" id="person_last_name" />
                      <span class="text-danger" v-for="message of validation_message.person_last_name"
                        :key="`person_last_name-${message}`">{{ message }}<br /></span>
                    </div>

                    <div class="col-sm-6">
                      <label class="form-label">Administrator’s Email Address<span class="text-danger">*</span>:</label>
                      <input type="text" class="form-control" v-model="data.person_email" id="person_email" />
                      <span class="text-danger" v-for="message of validation_message.person_email"
                        :key="`person_email-${message}`">{{ message }}<br /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 mb-3">
              <label class="form-label">Submit a question to one of our panellists/speakers:
                <p class="fst-italic" style="font-size: 14px">
                  (Please add the name of the panellist/speaker)
                </p>
              </label>
              <textarea type="text" class="form-control" rows="4" v-model="data.question" id="question"></textarea>
              <span class="text-danger" v-for="message of validation_message.question" :key="`question-${message}`">{{
                message }}<br /></span>
            </div>

            <div class="col-sm-6 mb-4 d-flex flex-column justify-content-between">
              <label class="form-label">How did you find out about this event<span class="text-danger">*</span>?</label>
              <select class="form-select text-primary" v-model="data.about_event" id="about_event">
                <option selected value="">Please select</option>
                <option value="I am an SIIA Member">I am an SIIA Member</option>
                <option value="Newsletter/I was invited">
                  Newsletter/I was invited
                </option>
                <option value="Social Media">Social Media</option>
                <option value="Referral: I found out from an SIIA Member/My Organisation">
                  Referral: I found out from an SIIA Member/My Organisation
                </option>
                <option value="I am a Speaker/Supporting Organisation">
                  I am a Speaker/Supporting Organisation
                </option>
                <option value="Others">Others</option>
              </select>
              <span class="text-danger" v-for="message of validation_message.about_event"
                :key="`about_event-${message}`">{{ message }}<br /></span>
            </div>
            <div class="col-sm-6 mb-4" v-if="data.about_event == 'Others'">
              <label class="form-label">Others<span class="text-danger">*</span>:</label>
              <input type="text" class="form-control mb-3" v-model="data.about_event_other" id="about_event_other" />
              <span class="text-danger" v-for="message of validation_message.about_event_other"
                :key="`about_event_other-${message}`">{{ message }}<br /></span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-4">
              <label class="form-label">Please indicate your interest to attend the workshop entitled
                “Strengthening Connectivity: Navigating Challenges and
                Opportunities in the ASEAN Digital Economy Framework Agreement
                (DEFA) Negotiations”, which will be held after the main forum
                from 3:50pm to 5pm.<span class="text-danger">*</span></label>
              <select class="form-select text-primary" v-model="data.interedted_in_workshop"
                id="interedted_in_workshop">
                <option value="yes">
                  Yes, I am interested in attending the workshop
                </option>
                <option value="no">
                  No, I am not interested in attending the workshop
                </option>
              </select>
              <span class="text-danger" v-for="message of validation_message.interedted_in_workshop"
                :key="`interedted_in_workshop-${message}`">{{ message }}<br /></span>
            </div>

            <div class="col-sm-12 mb-3">
              <div class="form-check form-check-inline mb-4">
                <input class="form-check-input me-2" type="checkbox" id="condition2" value="condition2"
                  v-model="data.condition2" />
                <label class="form-check-label" for="condition2">I would love to be informed about other SIIA events,
                  newly-released insights, and the latest publications.</label>
                <br />
                <span class="text-danger" v-for="message of validation_message.condition2"
                  :key="`condition2-${message}`">{{ message }}<br /></span>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input me-2" type="checkbox" id="condition" value="condition"
                  v-model="data.condition" />
                <label class="form-check-label" for="condition">By registering to this event, I agree to the
                  following<span class="text-danger">*</span>:</label>
                <br />
                <span class="text-danger" v-for="message of validation_message.condition"
                  :key="`condition-${message}`">{{ message }}<br /></span>

                <p>
                  <span class="fw-bold">1.1 </span> The Singapore Institute of
                  International Affairs will use the lawful basis of ‘legitimate
                  interest’ to pass on your contact details to sponsors/partners
                  of the event. They may contact you and their use of your data
                  will be governed by their privacy policy.
                </p>
                <p>
                  <span class="fw-bold">1.2 </span> Media. By attending the
                  Event you acknowledge and agree to grant the Singapore
                  Institute of International Affairs the right at the Event to
                  record, film, photograph or capture your likeness in any media
                  now available and hereafter developed and to distribute,
                  broadcast, use or otherwise globally to disseminate, in
                  perpetuity, such media without any further approval from you
                  or any payment to you. This grant to Singapore Institute of
                  International Affairs includes, but is not limited to, the
                  right to edit such media, the right to use the media alone or
                  together with other information, and the right to allow others
                  to use and/or disseminate the media.
                </p>
                <p>
                  <span class="fw-bold">1.3 </span> Event Content. You
                  acknowledge and agree that Singapore Institute of
                  International Affairs, in its sole discretion, reserves the
                  right to change all aspects of the Event, including but not
                  limited to, the Event name, themes, content, program,
                  speakers, performers, hosts, moderators, venue and time.
                </p>
                <p>
                  The Singapore Institute of International Affairs operates a
                  strict privacy policy. Please see our privacy policy
                  <a href="http://www.siiaonline.org/privacy-policy/" target="_blank">here</a>.
                </p>
              </div>
            </div>

            <div class="row my-3" v-if="this.success_alert">
              <div class="col-sm-8 mx-auto">
                <div class="alert alert-success" role="alert">
                  Thank you for registering to the event. You will receive a
                  post-registration email shortly. If you have any questions or
                  require any further assistance, please contact us at
                  <a href="mailto:events@siiaonline.org">events@siiaonline.org</a>. Thank you!
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="col-6 col-sm-2 mx-auto">
                <div class="d-grid">
                  <button @click="onSubmit" v-if="!loading"
                    class="btn btn-lg btn-primary border-primary fs-6 text-uppercase text-center">
                    SUBMIT
                  </button>
                  <button v-if="loading" class="btn btn-lg btn-primary border-primary fs-6 text-uppercase text-center"
                    disabled>
                    Please wait...
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";

export default {
  name: "register",

  data() {
    return {
      success_alert: false,
      loading: false,
      data: {
        workshop: "No",
        nationality: "",
        salutation: "",
        first_name: "",
        person: "",
        person_first_name: "",
        last_name: "",
        person_last_name: "",
        printed_name: "",
        email: "",
        person_email: "",
        phone: "",
        job_level: "",
        designation: "",
        organization: "",
        industry: "",
        industry_other: "",
        about_event_other: "",
        age: "",
        about_event: "",
        interedted_in_workshop: "yes",
        condition: false,
        condition2: false,
      },
      validation_message: {
        workshop: "",

        nationality: "",
        salutation: "",
        first_name: "",
        last_name: "",
        printed_name: "",
        email: "",
        phone: "",
        job_level: "",
        designation: "",
        organization: "",
        industry: "",
        industry_other: "",
        about_event_other: "",
        about_event: "",
        interedted_in_workshop: "",
        age: "",
        condition: "",
        condition2: "",
      },
    };
  },
  mounted() {
    this.globalDeviceCheck();
  },
  methods: {
    onSubmit() {
      // console.log(this.data);
      // return;
      this.loading = true;
      this.alert = false;
      this.resetValidation();
      if (!this.data.person) {
        this.data.person = null;
      }
      if (!this.data.condition) {
        this.data.condition = null;
      }

      if (!this.data.condition2) {
        this.data.condition2 = null;
      }

      Csrf.getCookie().then((res) => {
        Api.post("participant", this.data).then((res) => {
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            let fieldIdsFromAPI = Object.keys(res.data.validation_message);
            console.log("fieldIdsFromAPI", fieldIdsFromAPI);
            if (fieldIdsFromAPI.length > 0) {
              let firstFieldId = fieldIdsFromAPI[0];
              console.log("firstFieldId", firstFieldId);
              let firstFieldElement = document.getElementById(firstFieldId);
              console.log("firstFieldElement", firstFieldElement);
              if (firstFieldElement) {
                firstFieldElement.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                firstFieldElement.classList.add("is-invalid");
                firstFieldElement.focus();
              }
            }

            fieldIdsFromAPI.forEach(function (fieldId) {
              let fieldElement = document.getElementById(fieldId);
              if (fieldElement) {
                fieldElement.addEventListener("input", function () {
                  if (this.value === "") {
                    this.classList.add("is-invalid");
                  } else {
                    this.classList.remove("is-invalid");
                  }
                });
                fieldElement.dispatchEvent(new Event("input"));
              }
            });

            this.loading = false;

            return;
          }
          if (!res.data.error) {
            this.loading = false;
            this.resetValidation();
            this.successModal();
          }
        });
      });
    },

    resetValidation() {
      this.validation_message = {
        salutation: "",
        first_name: "",
        last_name: "",
        printed_name: "",
        email: "",
        phone: "",
        job_level: "",
        designation: "",
        organization: "",
        about_event: "",
        industry: "",
        industry_other: "",
        about_event_other: "",
        interedted_in_workshop: "",
        age: "",
        condition: "",
        condition2: "",
        nationality: "",
        workshop: "",
      };
    },
    successModal() {
      this.data = {
        salutation: "",
        first_name: "",
        last_name: "",
        printed_name: "",
        email: "",
        phone: "",

        job_level: "",
        designation: "",
        organization: "",
        industry: "",
        industry_other: "",
        about_event_other: "",
        interedted_in_workshop: "yes",
        about_event: "",
        age: "",
        condition: "",
        condition2: "",
        nationality: "",
        workshop: "",
      };
      this.loading = false;

      this.success_alert = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  min-height: 100vh;

  .no-underline {
    text-decoration: none;
  }

  .agreement {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    border: 2px solid;
    border-radius: 8px;
  }

  #signature {
    border: 2px solid;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>
